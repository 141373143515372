import React from "react";

import Layout from "../../components/Layout";
import FeatureBlock from "../../components/FeatureBlock";

import tr from "../../components/Translations.json";
import "../../styles/SelectionPage.scss";
import setIcon from "../../components/FeatureIcon";

export default function AdvancedIndex() {
  let features = tr.fr.features;

  let xfeatures = features.map((item) => (
    <FeatureBlock
      title={item.name}
      link={item.link}
      icon={setIcon(item.icon)}
    />
  ));

  return (
    <Layout title="Fonctionnalités avancées" noscroll>
      <div className="features-root">
        <h1>Fonctionnalités avancées</h1>
        <div className="features">{xfeatures}</div>
      </div>
    </Layout>
  );
}
